import * as React from 'react'
import '../assets/css/stylesheet.css'
import timerImg from '../assets/img/timer.svg'
import contabilidadImg from '../assets/img/contabilidad.svg'
import comprasImg from '../assets/img/compras.svg'
import megafonoImg from '../assets/img/megafono.svg'
import cyberSecurityImg from '../assets/img/cyber-security.svg'
import { useEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

export const IndexPage = (): React.ReactElement => {
	const divsPrices = ['precio1', 'precio2', 'precio3', 'precio4']

	const handlerPrices = (e: any | undefined) => {
		const parentNode = e?.target?.closest('.price-card')

		hidePricesHover(divsPrices)
		if (parentNode && divsPrices.includes(parentNode.id)) {
			showPricesHover(parentNode.id)
		}
	}

	const hidePricesHover = (divsPrices: string[]) => {
		for (let i = 0; i < divsPrices.length; i++) {
			const card = document.getElementById(divsPrices[i])
			if (card && card.classList.contains('display-none')) {
				const cardhover = document.getElementById(divsPrices[i] + '-hover')
				if (cardhover) {
					cardhover.classList.remove('display-flex')
					cardhover.classList.add('display-none')
				}
				card.classList.remove('display-none')
				card.classList.add('display-flex')
			}
		}
	}

	const showPricesHover = (id: string) => {
		const card = document.getElementById(id)
		const cardhover = document.getElementById(id + '-hover')
		if (card) {
			card.classList.remove('display-flex')
			card.classList.add('display-none')
		}
		if (cardhover) {
			cardhover.classList.remove('display-none')
			cardhover.classList.add('display-flex')
		}
	}

	useEffect(() => {
		window.document.addEventListener('click', handlerPrices, true)
		hidePricesHover(divsPrices)
	}, [])

	return (
		<Layout>
			<SEO title="CommerSight" />
			<section className="home" id="home">
				<div className="back-white">
					<div className="container-body">
						<div className="container-title">
							<h1>Facturación automática</h1>
							<p className="gray-text">
								Incrementa tu rentabilidad y aprovecha tu tiempo en las cosas importantes, nosotros nos
								encargamos de facturar tus ventas.
							</p>
							<div className="mercado">
								<StaticImage src="../assets/img/mercado-libre.png" alt="Mercado Libre" />
							</div>
						</div>
					</div>
				</div>
				<div className="back-blue">
					<div className="container-body">
						<div className="container-title">
							<h3>Aprovecha las funcionalidades extra que te da nuestra herramienta</h3>
							<span className="twenty">
								Incrementa tu rentabilidad y aprovecha tu tiempo en las cosas importantes, nosotros nos
								encargamos de facturar tus ventas.
							</span>
						</div>
					</div>
				</div>
				<div className="screenshot">
					<StaticImage className="screen" src="../assets/img/commersight-app.png" alt="screenshot" />
				</div>
			</section>

			<section className="back-white" id="function">
				<div className="container-body">
					<div className="title-section">
						<h2>¿Cómo funciona?</h2>
					</div>
					<div className="container-tab">
						<div className="tab-sky">
							<span>Vendedores</span>
						</div>
						<div className="tab-content-sky">
							<div className="step-left-column">
								<div className="step">
									<div className="step-number-sky">1</div>
									<div className="step-text-sky">Configura tu perfil</div>
								</div>
								<p>Carga tus certificados y completa tu información fiscal.</p>
							</div>
							<div className="step-right-column">
								<div className="step">
									<div className="step-number-sky">2</div>
									<div className="step-text-sky">Completa el código SAT de tus productos</div>
								</div>
								<p>
									Puedes configurar tus productos 1 a 1 o usar nuestra herramienta de edición masiva.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="back-blue">
					<div className="container-ribbon">
						<div className="icon-time">
							<img className="timer" src={timerImg} alt="timer" />
						</div>
						<div className="ribbon-icon">
							<span className="ribbon-txt-icon">Configurar la herramienta te lleva menos de 3 min.</span>
						</div>
					</div>
				</div>
				<div className="container-body">
					<div className="container-tab">
						<div className="tab-dark">
							<span>Compradores</span>
						</div>
						<div className="tab-content-dark">
							<div className="step-left-column">
								<div className="step">
									<div className="step-number-dark">1</div>
									<div className="step-text-dark">Realiza una compra</div>
								</div>
								<p>Recibirá un mensaje con un link para facturar.</p>
								<br />
								<p>
									Nuestro servicio evita el bloqueo de mensajes de Mercado Libre al cumplir con sus
									normas de seguridad.
								</p>
								<br />
								<p>Ofrece una mejor experiencia y mejora tu reputación.</p>
							</div>
							<div className="step-right-column">
								<div className="step">
									<div className="step-number-dark">2</div>
									<div className="step-text-dark">Recibe la factura</div>
								</div>
								<p>
									La factura se emite después de que el comprador reciba su producto. Así evitamos
									tener que cancelar facturas.
								</p>
								<br />
								<p>
									Se registra en Mercado Libre para que el comprador siempre pueda tener las facturas
									a la mano.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="back-white" id="pricing">
				<div className="container-body">
					<div className="title-section">
						<h2>Precios</h2>
					</div>
				</div>
				<div className="back-blue">
					<div className="container-ribbon">
						<div className="ribbon">
							<span className="ribbon-txt">
								Todos los precios tienen IVA incluido sin comisiones ocultas.
							</span>
						</div>
					</div>
				</div>
				<div className="container-body">
					<div className="rack">
						<div className="price-card display-flex" id="precio1">
							<div className="hd-1">
								<span>INTRO</span>
							</div>
							<div className="card-center">
								<div className="stamps">
									<span>25 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">3.44</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $86.00</span>
								</div>
							</div>
						</div>
						<div className="price-card-hover display-none" id="precio1-hover">
							<div className="hd-1-hover">
								<span>INTRO</span>
							</div>
							<div className="card-center-hover">
								<div className="stamps">
									<span>25 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">3.44</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $86.00</span>
								</div>
								<div className="btn-select">
									<span>
										<a href="https://admin.commersight.com/home" target="_blank">
											SELECCIONAR
										</a>
									</span>
								</div>
							</div>
						</div>
						<div className="price-card display-flex" id="precio2">
							<div className="hd-1">
								<span>BÁSICA</span>
							</div>
							<div className="card-center">
								<div className="stamps">
									<span>500 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">2.33</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $1,165.00</span>
								</div>
							</div>
						</div>
						<div className="price-card-hover display-none" id="precio2-hover">
							<div className="hd-1-hover">
								<span>BÁSICA</span>
							</div>
							<div className="card-center-hover">
								<div className="stamps">
									<span>500 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">2.33</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $1,165.00</span>
								</div>
								<div className="btn-select">
									<span>
										<a href="https://admin.commersight.com/home" target="_blank">
											SELECCIONAR
										</a>
									</span>
								</div>
							</div>
						</div>
						<div className="price-card display-none" id="precio3">
							<div className="hd-1">
								<span>ESTÁNDAR</span>
							</div>
							<div className="card-center">
								<div className="stamps">
									<span>1,000 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">1.99</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $1,990.00</span>
								</div>
							</div>
						</div>
						<div className="price-card-hover display-flex" id="precio3-hover">
							<div className="hd-1-hover">
								<span>ESTÁNDAR</span>
							</div>
							<div className="card-center-hover">
								<div className="stamps">
									<span>1,000 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">1.99</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $1,990.00</span>
								</div>
								<div className="btn-select">
									<span>
										<a href="https://admin.commersight.com/home" target="_blank">
											SELECCIONAR
										</a>
									</span>
								</div>
							</div>
						</div>
						<div className="price-card display-flex" id="precio4">
							<div className="hd-1">
								<span>PREMIUM</span>
							</div>
							<div className="card-center">
								<div className="stamps">
									<span>10,000 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">1.79</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $17,900.00</span>
								</div>
							</div>
						</div>
						<div className="price-card-hover display-none" id="precio4-hover">
							<div className="hd-1-hover">
								<span>PREMIUM</span>
							</div>
							<div className="card-center-hover">
								<div className="stamps">
									<span>10,000 Timbres</span>
								</div>
								<div className="cost">
									<span className="money-symbol">$</span>
									<span className="value">1.79</span>
								</div>
								<div className="divider" />
								<div className="currency">
									<span>MXN</span>
								</div>
								<div className="info-price">
									<span>Total: $17,900.00</span>
								</div>
								<div className="btn-select">
									<span>
										<a href="https://admin.commersight.com/home" target="_blank">
											SELECCIONAR
										</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="back-blue">
					<div className="container-ribbon">
						<div className="ribbon">
							<span className="ribbon-txt">
								¡Por cada amigo que invites y realice una compra en timbres, tú obtendrás un cupón que
								te devolverá el 10% de tu compra!
							</span>
						</div>
					</div>
				</div>
				<div className="container-body">
					<div className="subtitle">
						<h3>Herramientas gratuitas dentro de la aplicación</h3>
					</div>
					<div className="rack">
						<div className="tile">
							<div className="tile-blue-left">
								<div className="tile-txt-white">
									<span>Contabilidad</span>
								</div>
								<div className="tile-icon">
									<img className="tile-icon-size" src={contabilidadImg} alt="contability" />
								</div>
							</div>
							<div className="tile-white-right">
								<span className="tile-white-txt">
									Refacturación con 1 click.
									<br />
									Generación de Notas de Crédito automáticas.
									<br />
									Cancelación de Facturas automáticas.
									<br />
									Facturación consolidada.
									<br />
									Reportes en Excel.
								</span>
							</div>
						</div>
						<div className="tile">
							<div className="tile-blue-left">
								<div className="tile-txt-white">
									<span>Manejo de órdenes</span>
								</div>
								<div className="tile-icon">
									<img className="tile-icon-size" src={comprasImg} alt="buy" />
								</div>
							</div>
							<div className="tile-white-right">
								<span className="tile-white-txt">
									Ventas full.
									<br />
									Reclamos.
									<br />
									Cancelaciones.
									<br />
									Devoluciones.
								</span>
							</div>
						</div>
						<div className="tile">
							<div className="tile-white-left">
								<div className="tile-icon">
									<img className="tile-icon-size" src={megafonoImg} alt="soon" />
								</div>
								<div className="tile-txt-whiteb">
									<span>Próximamente</span>
								</div>
							</div>
							<div className="tile-blue-center">
								<div className="tile-txt-white">
									<span>Finanzas</span>
								</div>
								<div className="tile-icon">
									<img className="tile-icon-size" src={contabilidadImg} alt="contability" />
								</div>
							</div>
							<div className="tile-white-right">
								<span className="tile-white-txt">
									Informe de comisiones.
									<br />
									Informe de costos de envios.
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="back-blue" id="knowus">
				<div className="container-body">
					<div className="title-section">
						<h2>Conócenos</h2>
					</div>
					<div className="pill">
						<div className="subtitle">
							<h3>Somos la fusión de 3 empresas líderes en su sector.</h3>
						</div>
						<div className="logos-rack">
							<div className="logo-alliance">
								<a href="https://www.enapsys.com/" title="Preetail" target="_blank">
									<StaticImage
										className="img-size"
										src="../assets/img/enapsys-logo.png"
										alt="enapsys"
									/>
								</a>
							</div>
							<div className="logo-alliance">
								<a href="https://www.netcurio.com/" title="Preetail" target="_blank">
									<StaticImage
										className="img-size"
										src="../assets/img/netcurio-logo.png"
										alt="Netcurio"
									/>
								</a>
							</div>
							<div className="logo-alliance">
								<a href="#" title="Preetail" target="_blank">
									<StaticImage className="img-size" src="../assets/img/preetail.png" alt="Preetail" />
								</a>
							</div>
						</div>
						<span className="know">
							Somos una Empresa Internacional de desarrollo de software con{' '}
							<strong>más de 10 años de experiencia</strong>, enfocados a ofrecer sistemas automatizados y
							conectados a las principales plataformas de eCommerce.
							<br />
							<br />
							Para nosotros, entender los problemas de nuestros clientes es algo fundamental, por ello{' '}
							<strong>trabajamos día a día en el crecimiento de tu negocio</strong>, garantizando
							resultados óptimos en sus ventas.
						</span>
						<div className="subtitle">
							<h3>Usando tecnología de última generación</h3>
						</div>
						<div className="logos-rack">
							<div className="logo-last">
								<div className="logo-alliance">
									<StaticImage
										className="logo-last-size"
										src="../assets/img/aws.png"
										alt="Amazon Web Services"
									/>
								</div>
								<span className="know">
									Toda nuestra infraestructura corre sobre Amazon Web Services.
								</span>
							</div>
							<div className="logo-last">
								<div className="logo-alliance">
									<img className="logo-last-size" src={cyberSecurityImg} alt="KMS" />
								</div>
								<span className="know">
									Protegemos tu información confidencial cifrándola por medio de AWS Key Management
									System (KMS).
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default IndexPage
